export default [
    {
        "appid": 469610,
        "name": "Rick and Morty: Virtual Rick-ality",
    }, {
        "appid": 620980,
        "name": "Beat Saber",
    }, {
        "appid": 488310,
        "name": "Eleven Table Tennis",
    }, {
        "appid": 546560,
        "name": "Half-Life: Alyx",
    }, {
        "appid": 555160,
        "name": "Pavlov VR",
    }, {
        "appid": 578620,
        "name": "GORN",
    }, {
        "appid": 617830,
        "name": "SUPERHOT VR",
    }, {
        "appid": 629730,
        "name": "Blade & Sorcery",
    }, {
        "appid": 732690,
        "name": "FIVE NIGHTS AT FREDDY'S: HELP WANTED",
    }, {
        "appid": 739630,
        "name": "Phasmophobia",
    }, {
        "appid": 752480,
        "name": "Sniper Elite VR",
    }, {
        "appid": 804490,
        "name": "Creed: Rise to Glory™",
    }, {
        "appid": 1001140,
        "name": "Angry Birds VR: Isle of Pigs",
    }, {
        "appid": 1092430,
        "name": "Budget Cuts 2: Mission Insolvency",
    }, {
        "appid": 1104380,
        "name": "The Room VR: A Dark Matter",
    }, {
        "appid": 916840,
        "name": "The Walking Dead: Saints & Sinners",
    }, {
        "appid": 1363430,
        "name": "Propagation VR",
    }, {
        "appid": 1575520,
        "name": "Fruit Ninja VR 2",
    }, {
        "appid": 1292040,
        "name": "STRIDE",
    }, {
        "appid": 242760,
        "name": "The Forest",
    }, {
        "appid": 503770,
        "name": "Elven Assassin",
    }, {
        "appid": 691260,
        "name": "POPULATION: ONE",
    }, {
        "appid": 342180,
        "name": "Arizona Sunshine",
    }, {
        "appid": 1523720,
        "name": "Cook-Out",
    }, {
        "appid": 567670,
        "name": "Serious Sam 3 VR",
    }, {
        "appid": 502820,
        "name": "Batman™: Arkham VR",
    }, {
        "appid": 1183030,
        "name": "Layers of Fear VR",
    }, {
        "appid": 751630,
        "name": "After the Fall"
    }, {
        "appid": 559330,
        "name": "A Fisherman's Tale"
    }, {
        "appid": 1429640,
        "name": "HINGE VR"
    }, {
        "appid": 722230,
        "name": "L.A. Noire: The VR Case Files"
    },
]