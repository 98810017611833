import React from 'react';
import {Box, Card, CardBody, Heading, Image, Grid} from "grommet";
import games from '../games';
import {outboundClick} from "../analytics";

const PickAGame = ({...rest}) => {
    return (
        <Box {...rest} pad={{horizontal: 'xlarge'}} id='pick-a-game'>
            <Heading alignSelf='center' level={2} size='large'>Избери игра</Heading>
                <Grid columns="220px" gap="medium" justify="center">
                {games.map(game => <Card key={game.appid} onClick={() => {
                    const url = `//store.steampowered.com/app/${game.appid}`;
                    outboundClick(url, game.name);
                    window.open(url, '_newtab');
                }}>
                    <CardBody>
                        <Image
                            fit='cover'
                            width={243}
                            height={114}
                            src={`//steamcdn-a.akamaihd.net/steam/apps/${game.appid}/header.jpg`}
                            // src={`//media.steampowered.com/steamcommunity/public/images/apps/${game.appid}/${game.img_logo_url}.jpg`}
                            a11yTitle={game.name}
                        />
                    </CardBody>
                </Card>)}
                </Grid>
        </Box>
    );
};

export default PickAGame;