import React from 'react';
import {Box, Button, Heading, Text} from "grommet";
// linear-gradient(35deg, #af12da 0%, #fed09b 100%)
// linear-gradient(35deg, #2454cd 0%, #6eefdc 100%)
// #430678, #000522
const GiftCards = () => {
    return (
        <Box background='linear-gradient(35deg, #430678 0%, #000522 100%)' pad={{horizontal: 'xlarge', vertical: 'large'}} direction='row-responsive' justify='center' gap='xlarge' align='center'>
            <Heading color='white' level={3}>Ваучер за подарък</Heading>
            <Text color='white'>Подари невероятно изживяване във виртуалното пространство<br/> на твоите приятели и семейство с този ваучер.</Text>
            <Button primary label='Купи сега' size='large' alignSelf='center'/>
        </Box>
    );
};

export default GiftCards;