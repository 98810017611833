import React from 'react';
import {Accordion, AccordionPanel, Box, Heading, Paragraph} from "grommet";
import {gaEvent} from "../analytics";

const Panel = ({title, body}) => (
    <AccordionPanel label={title}>
        <Box pad={{horizontal: 'medium', vertical: 'small'}}>
            {typeof body === 'string' ? <Paragraph>{body}</Paragraph> : body}
        </Box>
    </AccordionPanel>
)

const Faq = () => {
    return (
        <Box id='faq' justify='center' pad={{horizontal: 'xlarge', vertical: 'large'}} align='center'>
            <Heading level={2} size='large'>Често задавани въпроси</Heading>
            <Accordion margin={{top: 'medium'}} width='large' onActive={(panels) => gaEvent('click', {event_category: 'read-faq', event_label: panels.join(',')})}>
                <Panel
                    title='Какво е виртуална реалност?'
                    body='Витруалната реалност представлява компютърно генерирана реалност, която е проектирана да симулира физическото присъствие на човек в специфична среда. Целта на VR е да позволи на потребителя да усети и манипулира заобикалящата го среда, сякаш това е реалният свят.'
                />
                <Panel
                    title='Съществува ли възможност да ми прилошее?'
                    body='Заглавията в библиотеката ни притежават модерни технологии, които имат за цел да намалят до минимум рискът от дискомфорт, но такъв все пак съществува.'
                />
                <Panel
                    title='Какви са възрастовите ограничения?'
                    body='Такива няма, но препоръчителното възраството ограничение за повечето игри е за лица над 9 години.'
                />
                <Panel
                    title='Задължително ли е да си направя резервация преди да дойда?'
                    body='Да, можете да го направите през сайта или на посочения номер.'
                />
                <Panel
                    title='Задължително ли е резервацията да е с минимална продължителност 1 час?'
                    body='Ако желаете да си направите резервация за някоя от аркадните ни игри, които имат малка продължителност (боксов мач, тенис на маса..), можете да го добавите като описание при резервацията в сайта или да позвъните на съответния номер.'
                />
                <Panel
                    title='Въведени ли са мерки срещу COVID?'
                    body='Предприели сме всичко необходимо за безопасността на участниците- задължителна дезинфекция на очилата и джойстиците след всяко използване, еднократни маски за VR очилата, ограничен брой на участниците в групите.'
                />
                <Panel
                    title='Ако нося очила?'
                    body='Тъй като VR очилата притежават механизъм за регулация на дълбочината, няма да има никакъв проблем да ги използвате по време на игра.'
                />
                <Panel
                    title='С колко виртуални клетки разполага VR клубът?'
                    body='Разполагаме с 4 клетки с размери 3х3 метра, като за момента годни за експлоатация са 3 от тях.'
                />
                <Panel
                    title='Как се движа в игрите?'
                    body='За игрите, изискващи интензивно движение, се използат джойстиците.'
                />
                <Panel
                    title='Какъв е капацитетът на VR клуба?'
                    body='За момента VR клубът има възможност за 12 посетители - 9 стоящи и 3-ма играещи.'
                />
                <Panel
                    title='Необходимо ли е едно резервирано поле да бъде използвано само от 1 човек?'
                    body='Не, има възможност 2ма посетители, редувайки се, да използват 1 поле.'
                />
            </Accordion>
        </Box>
    );
};

export default Faq;