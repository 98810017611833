import axios from 'axios';

const API_BASE_URL = process.env.GATSBY_APPOINTMENT_API_URL;

const api = axios.create({
    baseURL: API_BASE_URL,
    timeout: 15000,
    headers: {'Content-Type': 'application/json'},
});


export default api;