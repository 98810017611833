import React from 'react';
import {Anchor, Box, Heading, Text} from "grommet";
import BookingCalendar from "./BookingCalendar";
import {PhoneVertical, Send} from "grommet-icons";
import {generateLead} from "../analytics";

const Bullet = ({number, text}) => (
    <Box direction='row' gap='small' align='center'>
        <Box background='#EE0C39' round='full' width='40px' height='40px' align='center' justify='center' flex={false}>
            <Text weight='bold' size='large' color='white'>{number}</Text>
        </Box>
        <Text>{text}</Text>
    </Box>
)

const JoinIn = ({rest}) => {
    return (
        <Box {...rest} id='join-in'>
            <Heading level={2} size='xlarge' color='#f3e4d2' margin={{bottom: 'none', left: 'xlarge'}}
                     style={{lineHeight: '40px'}}>Запази час</Heading>
            <Box pad={{vertical: 'xlarge', horizontal: 'medium'}} background='#f3e4d2' justify='center' align='center'
                 direction='row-responsive' gap='xlarge'>
                <Box>
                    <BookingCalendar/>
                    {/*<Text weight='bold' margin={{top: 'medium'}} alignSelf='center'>*/}
                    {/*    <Text weight='bold' size='large' color='status-error'>*</Text> Няма да работим за периода 28.08.2022 - 01.09.2022*/}
                    {/*</Text>*/}
                </Box>
                <Box>
                    <Heading level={3}>Как работи?</Heading>
                    <Box gap='medium'>
                        <Bullet number={1} text='Избери подходящ за теб ден и час, след което въведи твоите данни'/>
                        <Bullet number={2} text='Ще получиш имейл/смс за потвръждение с всичката информация'/>
                        <Bullet number={3} text='Ела навреме и избери твоето приключение на място. Забавлявай се!'/>
                    </Box>
                    <Heading margin={{top: 'large'}} level={3}>Затрудняваш се или имаш въпрос?</Heading>
                    <Box gap='medium' pad={{left: 'xsmall'}}>
                        <Box direction='row' gap='small' align='center'>
                            <PhoneVertical size='32px' color='#111111'/>
                            <Anchor onClick={() => generateLead('by_phone')} href='tel:+359879203242'>0879 203 242</Anchor>
                        </Box>
                        <Box direction='row' gap='small' align='center'>
                            <Send size='32px' color='#111111'/>
                            <Anchor onClick={() => generateLead('by_email')} href='mailto:info@mainaverse.com'>info@mainaverse.com</Anchor>
                        </Box>
                    </Box>
                    {/*<Box*/}
                    {/*    // justify='center'*/}
                    {/*    align='center'*/}
                    {/*    width='417px'*/}
                    {/*    height='178px'*/}
                    {/*    background='url("https://themes.themeenergy.com/activity/virtual-reality/wp-content/uploads/sites/4/2020/03/coupon.gif")'*/}
                    {/*>*/}
                    {/*    <Text weight='bold' size='large' margin={{left: 'large', top: 'small'}}>БЕЗПЛАТНА ИГРА</Text>*/}
                    {/*    <Text weight='bold' size='xxlarge' margin={{left: 'large', top: 'medium'}}>15 min</Text>*/}

                    {/*    <Text margin={{top: '48px', left: '54px'}}>за всеки нов посетител</Text>*/}
                    {/*</Box>*/}
                </Box>
            </Box>
        </Box>
    );
};

export default JoinIn;