import React, {useCallback, useState} from 'react';
import {Button, Layer, TextInput, FormField, Box, Heading, Text, MaskedInput, Select, TextArea, Spinner} from "grommet";
import {useFormik} from "formik";
import * as Yup from "yup"
import moment from "moment";
import api from "../config/axios";
import {Checkmark, Close} from "grommet-icons";
import {fbEvent, gaEvent} from "../analytics";

const APPOINTMENTS_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const APPOINTMENTS_DATE_FORMAT = 'YYYY-MM-DD';

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Задължително поле'),
    lastName: Yup.string().required('Задължително поле'),
    email: Yup.string().email("Моля въведете валиден имейл").required("Задължително поле"),
    phone: Yup.string().required("Задължително поле"),
    headsets: Yup.number().required("Задължително поле"),
    notes: Yup.string()
})

export const phoneMask = [
    { fixed: "+(" },
    {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: "xxx",
    },
    { fixed: ")" },
    { fixed: " " },
    {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: "xxx",
    },
    { fixed: "-" },
    {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: "xxx",
    },
    { fixed: "-" },
    {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: "xxx",
    },
];

const regularPrices = {
    1: 25,
    2: 50,
    3: 70,
    4: 90
}

const prices = {
    1: 25,
    2: 45,
    3: 65,
    4: 80
}

const BookingModal = ({slot, setSlot, date}) => {
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const {errors, values, handleChange, handleBlur, handleReset, handleSubmit, setFieldValue} = useFormik({
        validationSchema,
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '+(359) ',
            headsets: undefined,
            notes: ''
        },
        onSubmit: async (values, actions) => {
            setError(undefined);
            setLoading(true);
            const timeslot = slot.slot.split(' - ')
            const startTime = moment(timeslot[0], 'HH:mm')
            const endTime = moment(timeslot[1], 'HH:mm')
            const startDatetime = moment(date).set({hours: startTime.hours(), minutes: startTime.minutes(), seconds: 0}).format(APPOINTMENTS_DATETIME_FORMAT);
            const endDatetime = moment(date).set({hours: endTime.hours(), minutes: endTime.minutes(), seconds: 0}).format(APPOINTMENTS_DATETIME_FORMAT);
            try {
                await api.post('/appointments', {
                    appointment: {
                        start: startDatetime,
                        end: endDatetime,
                        notes: values.notes,
                    },
                    headsets: values.headsets,
                    customer: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phone: values.phone
                    }})
                setCompleted(true);
                fbEvent('Purchase', {
                    currency: 'EUR',
                    content_name: slot.slot,
                    content_category: moment(date).format(APPOINTMENTS_DATE_FORMAT),
                    num_items: values.headsets,
                    value: prices[values.headsets] / 1.96
                });
                gaEvent('purchase', {
                    currency: 'EUR',
                    value: prices[values.headsets] / 1.96,
                    transaction_id: `${startDatetime}, ${values.headsets}`,
                    date: moment(date).format(APPOINTMENTS_DATE_FORMAT),
                    slot: slot.slot,
                    items: [{
                        item_name: `${values.headsets} Headsets`,
                        currency: 'EUR',
                        value: prices[values.headsets] / 1.96,
                        item_variant: 'Steam Games'
                    }],
                    num_headsets: values.headsets
                });
            } catch (er) {
                if (er.error) {
                    setError(er.error);
                } else {
                    setError('Нещо се обърка. Моля направете резервация по телефона.');
                }
            }
        }
    })
    const closeModal = useCallback(() => {
        setSlot(undefined);
        setCompleted(false);
    }, [setSlot]);

    return (<Layer
            onEsc={closeModal}
            onClickOutside={closeModal}
        >
        <Button alignSelf='end' icon={<Close size='small'/>} color='status-error' primary style={{position: 'fixed', right: 10, top: 10}}
                onClick={closeModal}/>
        <Box pad='medium' overflow='auto'>
        <Heading alignSelf='center'>Резервирай час</Heading>
            <Text alignSelf='center' weight='bold' size='large' margin={{bottom: 'large'}}>{slot.slot}, {moment(date).format('D MMMM YYYY г.')}</Text>
            {completed ? <Box direction='row' alignSelf='center' gap='small' align='center' pad={{bottom: 'medium'}}>
                    <Checkmark color='status-ok' size='large'/>
                    <Box>
                        <Text size='xlarge' weight='bold'>Успешна резервация!</Text>
                        <Text size='large'>Очакваме Ви!</Text>
                    </Box>
            </Box> :
            <form onReset={handleReset} onSubmit={handleSubmit}>
                <Box direction='row' gap='small'>
                    <FormField label="Собствено име" error={errors.firstName} required>
                        <TextInput
                            name="firstName"
                            onBlur={handleBlur}
                            value={values.firstName}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField label="Фамилно име" error={errors.lastName} required>
                        <TextInput
                            name="lastName"
                            onBlur={handleBlur}
                            value={values.lastName}
                            onChange={handleChange}
                        />
                    </FormField>
                </Box>
                <Box direction='row' gap='small'>
                    <FormField label="Email" error={errors.email} required>
                        <TextInput
                            name="email"
                            onBlur={handleBlur}
                            value={values.email}
                            onChange={handleChange}
                        />
                    </FormField>
                    <FormField label="Телефон" error={errors.phone} required>
                        <MaskedInput
                            mask={phoneMask}
                            name="phone"
                            onBlur={handleBlur}
                            value={values.phone}
                            onChange={handleChange}
                        />
                    </FormField>
                </Box>
                <FormField label="Бележка" error={errors.notes}>
                    <TextArea
                        name='notes'
                        rows={4}
                        placeholder='Имате въпрос или знаете коя игра искате да играете? Опишете тук, за да приготвим всичко необходимо.'
                        value={values.notes}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                </FormField>
                <FormField label="Брой полета" error={errors.headsets} required>
                    <Select
                        options={Array.from({length: slot.available}, (_, i) => i + 1)}
                        name='headsets'
                        labelKey={(key) => key > 1 ? `${key} полета` : `${key} поле`}
                        value={values.headsets}
                        onBlur={handleBlur}
                        onChange={({ option }) => setFieldValue('headsets', option)}
                    />
                </FormField>
                {values.headsets && <Box direction='row' gap='small' justify='center' align='center'>
                    <Text size='large'>Цена</Text>
                    <Box direction='row' gap='small' align='center'>
                        <Text
                            weight={prices[values.headsets] === regularPrices[values.headsets] ? 'bold' : 'normal'}
                            size={prices[values.headsets] === regularPrices[values.headsets] ? 'large' : 'medium'}
                            style={{textDecoration: prices[values.headsets] === regularPrices[values.headsets] ? 'none' : 'line-through'}}
                        >
                            {regularPrices[values.headsets]} лв
                        </Text>
                        {prices[values.headsets] < regularPrices[values.headsets] && <Text size='large' weight='bold' color='status-error'>{prices[values.headsets]} лв</Text>}
                    </Box>
                </Box>}
                <Box>{error && <Text alignSelf='center' color='status-critical'>{error}</Text>}</Box>
                <Box direction='row' gap='small' justify='center' margin={{top: 'medium'}}>
                    <Button label='Запази' primary type='submit' icon={loading ? <Spinner/> : undefined} disabled={loading}/>
                    <Button label='Откажи' onClick={closeModal}/>
                </Box>
            </form>}
        </Box>
        </Layer>);
}

export default BookingModal;