import React from 'react';
import {Box, Button, Heading, Paragraph} from "grommet";
import {StaticImage} from "gatsby-plugin-image";
import {gaEvent} from "../analytics";

const TeamBuilding = ({...rest}) => {
    return (
        <Box {...rest} direction='row-responsive' justify='center' pad='large' gap='medium'>
            <Box>
                <Heading level={2} size='large'>Тийм Билдинг и Партита</Heading>
                <Paragraph>VR студиото е много подходящо за групови събирания като тиймбилдинг, рождени дни, или просто събиране с приятели.</Paragraph>
                <Button onClick={() => gaEvent('click', {event_category: 'team-building', event_label: 'Team Building'})} primary size='large' label='Свържи се с нас' alignSelf='start' margin={{top: 'small'}} href='#visit-us'/>
            </Box>
            <Box direction='row' gap='medium' align='end'>
                <Box>
                    <StaticImage
                        src='../images/vive-pro-2-man.jpg' alt='Man with Vive Pro 2 Headset on'
                        width={500}
                        style={{borderRadius: 10}}
                    />
                </Box>
                <Box gap='medium'>
                    <StaticImage
                        src='../images/vr-07.jpeg' alt='Laptop'
                        style={{borderRadius: 10}}
                        width={290}
                    />
                    <StaticImage
                        src='../images/vr-08.jpeg' alt='Hand'
                        style={{borderRadius: 10}}
                        width={290}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default TeamBuilding;