import React from 'react';
import {Anchor, Box, Heading, NameValueList, NameValuePair, Text} from "grommet";
import Map from "./Map";
import {Feature} from "./HowDoesItWork";
import {Facebook, Instagram} from "grommet-icons";
import {generateLead} from "../analytics";

const workingHours = [
    {day: 'Понеделник', hours: '10:00 до 21:00'},
    {day: 'Вторник', hours: '10:00 до 21:00'},
    {day: 'Сряда', hours: '10:00 до 21:00'},
    {day: 'Четвъртък', hours: '10:00 до 21:00'},
    {day: 'Петък', hours: '10:00 до 21:00'},
    {day: 'Събота', hours: '10:00 до 21:00'},
    {day: 'Неделя', hours: '10:00 до 21:00'}
];

const VisitUs = ({...rest}) => {
    return (
        <Box {...rest} id='visit-us'>
            <Heading level={2} size='xlarge' color='#f3e4d2' margin={{bottom: 'none', left: 'xlarge'}}
                     style={{lineHeight: '40px'}}>Посети ни</Heading>
            <Box pad={{vertical: 'xlarge', horizontal: 'medium'}} background='#f3e4d2' justify='center' align='center'
                 direction='row-responsive' gap='large' wrap>
                <Box background='white' round='medium' pad='medium' elevation='large'>
                    <Map/>
                </Box>
                <Box>
                    <Heading level={3}>Работно време</Heading>
                    <NameValueList>
                        {workingHours.map(({day, hours}) =>
                            <NameValuePair name={day} key={day}>
                                <Text color="text-strong">{hours}</Text>
                            </NameValuePair>)}
                    </NameValueList>
                </Box>
            </Box>
            <Box background='#f1dec9' pad='large' direction='row-responsive' justify='center' gap='large'>
                <Feature
                    title='Адрес'
                    text={<Text>ТЦ Гранд ет. 2<br/>ул. Капитан Райчо 56<br/>Пловдив</Text>}
                />
                <Feature
                    title='Телефон'
                    text={<Anchor onClick={() => generateLead('by_phone')} href='tel:+359879203242' label='0879 203 242'/>}
                />
                <Feature
                    title='Имейл'
                    text={<Anchor onClick={() => generateLead('by_email')} href='mailto:info@mainaverse.com' label='info@mainaverse.com'/>}
                />
                <Feature
                    title='Социални мрежи'
                    text={<Box gap='small' direction='row'>
                        <Anchor onClick={() => generateLead('by_facebook')} href='https://www.facebook.com/mainaverse/' target='_blank'><Facebook color='plain' size='32px'/></Anchor>
                        <Anchor onClick={() => generateLead('by_instagram')} href='https://www.instagram.com/mainaverse/' target='_blank'><Instagram color='plain' size='32px'/></Anchor>
                    </Box>}
                />
            </Box>
        </Box>
    );
};

export default VisitUs;