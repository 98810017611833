import * as React from "react"
import {Box, Button, Heading, Paragraph, Text} from "grommet";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import HowDoesItWork from "../components/HowDoesItWork";
import TeamBuilding from "../components/TeamBuilding";
import JoinIn from "../components/JoinIn";
import GiftCards from "../components/GiftCards";
import PlacePhotosCarousel from "../components/PlacePhotosCarousel";
import FAQ from "../components/FAQ";
import VisitUs from "../components/VisitUs";
import PickAGame from "../components/PickAGame";
import {gaEvent} from "../analytics";
import Pricing from "../components/Pricing";
import VideoContentYT from "../components/VideoContentYT";

const IndexPage = () => {
  return (
      <Layout content={<Box width={{max: '1200px'}} height={{min: '600px'}} justify='center' animation='slideRight'>
          <Heading level={1} size='large' color='white'>Сетивно изживяване от друго измерение</Heading>
          <Button onClick={() => gaEvent('click', {event_category: 'join-in', event_label: 'Join In'})} label={<Text weight='bold' size='xlarge'>Запази час</Text>} primary size='large' alignSelf='start' href='#join-in'/>
      </Box>}>
        <Seo title="Mainaverse" />
            <Box direction='row-responsive' justify='center' margin={{top: 'xlarge'}} gap='xlarge' pad='large' animation='slideUp'>
                <Box
                    id='youtube-video'
                    alignSelf='center'
                    round='small'
                    elevation='xlarge'
                    width='large'
                >
                    <VideoContentYT style={{borderRadius: 10}} src="qE6-5bpEV9k" params={{autoPlay: true, title: 'Mainaverse'}}/>
                </Box>
                <Box alignSelf='center'>
                    <Heading alignSelf='center' level={2} size='large'>Virtual Reality<br/>Игрална Арена</Heading>
                    <Paragraph>Mainaverse Ви предоставя уникално и единствено по рода си в България 3D кинематографично изживяване. Можете да избирате от десетки игри, които са групово-базирани или индивидуални. Потопете се в едно ново измерение с вашите приятели или колеги, в което ще комуникирате помежду си, ще клякате, ще скачате и ще стреляте.</Paragraph>
                </Box>
            </Box>
          <HowDoesItWork margin={{top: 'xlarge'}}/>
          <PickAGame margin={{top: 'large'}}/>
          <TeamBuilding margin={{top: 'xlarge'}}/>
          <Pricing/>
          <JoinIn margin={{top: 'xlarge'}}/>
          <GiftCards/>
          <PlacePhotosCarousel/>
          <FAQ/>
          <VisitUs margin={{top: 'xlarge'}}/>
      </Layout>
  )
}

export default IndexPage
