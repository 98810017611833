import React, {useEffect, useMemo, useState} from 'react';
import {Box, Button, Calendar, Heading, List, Spinner, Text} from "grommet";
import {Close, FormNext, FormPrevious} from "grommet-icons";
import moment from "moment";
import api from "../config/axios";
import BookingModal, {APPOINTMENTS_DATE_FORMAT} from "./BookingModal";
import {fbEvent, gaEvent} from "../analytics";

const getCalendarBounds = () => [moment().toISOString(), moment().add({months: 3}).toISOString()];

const transformTimeslots = (data) => {
    const timeslots = [];
    Object.keys(data).forEach(key => {
        const end = moment(key, 'HH:mm').add({hours: 1}).format('HH:mm')
        timeslots.push({slot: `${key} - ${end}`, available: data[key]})
    })

    return timeslots.sort((a, b) => a.slot.localeCompare(b.slot));
}

const BookingCalendar = () => {
    const [date, setDate] = useState(undefined);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [selectedSlot, setSelectedSlot] = useState(undefined);
    // const calendarBounds = useMemo(() => ([moment().toISOString(), moment().add({months: 3}).toISOString()]), []);
    const selectedDateIsToday = useMemo(() => moment(date).isSame(moment(), 'day'), [date]);
    useEffect(() => {
        if (date) {
            setLoading(true);
            setError(undefined);
            (async () => {
                try {
                    const response = await api.get('/slots', {
                        params: {date: moment(date).format('YYYY-MM-DD')}
                    });
                    setData(transformTimeslots(response.data));
                } catch (err) {
                    setError('Нещо се обърка, моля напревете резервация по телефона.')
                } finally {
                    setLoading(false);
                }
            })()
        }
    }, [date, selectedSlot]);

    return (
        <Box background='white' round='medium' pad='medium' width='460px' height='450px' alignSelf='center'
             elevation='large'>
            {!!selectedSlot && <BookingModal slot={selectedSlot} setSlot={setSelectedSlot} date={date}/>}
            {date ?
                <Box>
                    <Box direction='row' gap='medium' justify='between' align='center'>
                        <Heading level={3} margin={{
                            top: 'small',
                            bottom: 'small'
                        }}>{moment(date).format('D MMMM YYYY г.')}</Heading>
                        <Box direction='row' gap='small'>
                            <Box direction='row'>
                                <Button plain icon={<FormPrevious size='36px'/>}
                                        onClick={() => setDate(date => moment(date).subtract(1, 'day').toISOString())}
                                />
                                <Button plain icon={<FormNext size='36px'/>}
                                        onClick={() => setDate(date => moment(date).add(1, 'day').toISOString())}
                                />
                            </Box>
                            <Button icon={<Close size='small'/>} color='status-error' primary
                                    onClick={() => setDate(undefined)}/>
                        </Box>
                    </Box>
                    <Text size='large' margin={{top: 'xsmall'}}>Свободни часове</Text>
                    <Box overflow='auto'>
                        {loading ? <Box justify='center' align='center'><Spinner/></Box> : data.length > 0 ? <List
                            margin={{top: 'small'}}
                            itemKey='slot'
                            primaryKey={(item) =>
                                <Box>
                                    <Text weight='bold'>{item.slot}</Text>
                                    {item.available > 0 ? <Text>{item.available > 1 ? `${item.available} свободни места` : '1 свободно място'} </Text> : null}
                                </Box>}
                            secondaryKey={(item) =>
                                <Button
                                    key={item.slot}
                                    primary
                                    onClick={() => {
                                        setSelectedSlot(item);
                                        fbEvent('AddToCart', {
                                            content_name: item.slot,
                                            content_category: moment(date).format(APPOINTMENTS_DATE_FORMAT),
                                        });
                                        gaEvent('add_to_cart', {
                                            event_label: moment(date).format(APPOINTMENTS_DATE_FORMAT),
                                            event_category: item.slot
                                        });
                                        gaEvent('begin_checkout', {
                                            date: moment(date).format(APPOINTMENTS_DATE_FORMAT),
                                            slot: item.slot
                                        });
                                    }}
                                    disabled={!item.available}
                                    label={item.available ? 'Запази' : 'Зает'}
                                    color={item.available ? undefined : 'light-5'}
                                />
                            }
                            data={data}
                        /> : <Text margin={{top: 'small'}}
                                   weight='bold'>{selectedDateIsToday ? 'Моля направете резервация по телефона.' : 'Няма свободни места.'}</Text>}
                        {error && <Text color='status-critical'>{error}</Text>}
                    </Box>
                </Box> :
                <Calendar
                    fill
                    date={date}
                    bounds={getCalendarBounds()}
                    // disabled={[['2022-08-28', '2022-09-01']]}
                    onSelect={setDate}
                    daysOfWeek
                    firstDayOfWeek={1}
                    locale='bg-BG'
                />
            }

        </Box>
    );
};

export default BookingCalendar;