import React, {useContext} from 'react';
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api'
import {ResponsiveContext, Spinner} from "grommet";

const options = {
}

const center = {
    lat: 42.1419681,
    lng: 24.7561498
};

const Map = () => {
    const size = useContext(ResponsiveContext);
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_KEY
        // ...otherOptions
    })
    const renderMap = () => {
        // wrapping to a function is useful in case you want to access `window.google`
        // to eg. setup options or create latLng object, it won't be available otherwise
        // feel free to render directly if you don't need that
        // const onLoad = React.useCallback(
        //     function onLoad (mapInstance) {
        //         // do something with map Instance
        //     }
        // )
        return <GoogleMap
            mapContainerStyle={{height: '500px', width: size === 'small' ? undefined : '500px', borderRadius: 10}}
            options={options}
            center={center}
            zoom={14}
            // onLoad={onLoad}
        >
            <Marker position={center} title='Mainaverse'/>
        </GoogleMap>
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? renderMap() : <Spinner />
};

export default Map;