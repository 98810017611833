import React, {Fragment, useContext, useMemo} from 'react';
import {Box, Carousel, ResponsiveContext} from "grommet";
import {StaticImage} from "gatsby-plugin-image";

const data = [
    <StaticImage src='../images/carousel/1.jpg' alt='Mainaverse Photo'/>,
    <StaticImage src='../images/carousel/2.jpg' alt='Mainaverse Photo'/>,
    <StaticImage src='../images/carousel/3.jpg' alt='Mainaverse Photo'/>,
    <StaticImage src='../images/carousel/4.jpg' alt='Mainaverse Photo'/>,
    <StaticImage src='../images/carousel/5.jpg' alt='Mainaverse Photo'/>,
    <StaticImage src='../images/carousel/6.jpg' alt='Mainaverse Photo'/>,
    <StaticImage src='../images/carousel/7.jpg' alt='Mainaverse Photo'/>,
    <StaticImage src='../images/carousel/8.jpg' alt='Mainaverse Photo'/>,
    <StaticImage src='../images/carousel/9.jpg' alt='Mainaverse Photo'/>,
];

const View = ({images}) => (
    <Box direction="row">
        {images.map((image, index) => <Fragment key={index}>{image}</Fragment>)}
    </Box>
)

const PlacePhotosCarousel = () => {
    const size = useContext(ResponsiveContext);
    const imagesPerCarousel = useMemo(() => size === 'small' ? 1 : 3, [size]);
    const views = useMemo(() => data.length / imagesPerCarousel, [imagesPerCarousel]);

    return (
        <Box>
            <Carousel controls='arrows'>
                {[...Array(views).keys()].map((index) =>
                    <View key={index} images={data.slice(index * imagesPerCarousel, index * imagesPerCarousel + imagesPerCarousel)}/>
                )}
            </Carousel>
        </Box>
    );
};

export default PlacePhotosCarousel;