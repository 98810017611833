import React from 'react';
import {Anchor, Box, Heading, Paragraph, Text} from "grommet";
import {StaticImage} from "gatsby-plugin-image";
import {Group, User} from "grommet-icons";

const Panel = ({title, description, prices, image, priceHeading}) => {
    return (
        <Box width='medium' align='center' margin={{top: 'medium'}}>
            <Box
                alignSelf='center'
                round='small'
                elevation='xlarge'
            >
                {image}
            </Box>
            {typeof title === 'string' ? <Heading level={3}>{title}</Heading> : title}
            {typeof description === 'string' ? <Paragraph style={{width: 300}}>{description}</Paragraph> : description}
            <Box>
                <Box direction='row' gap='xlarge' justify='between'>
                    <Text color='neutral-1' weight='bold'>Игрални полета</Text>
                    <Text weight='bold' color='neutral-1'>{priceHeading}</Text>
                </Box>
                {prices.map((price, index) => <Box key={index} direction='row' gap='xlarge' justify='between'
                                                   margin={{top: 'small'}}>
                    <Box direction='row' gap='xsmall'>{price.name > 1 ? <Group/> :
                        <User/>}<Text>{price.name}</Text></Box>
                    <Box direction='row' gap='small' align='center'>
                        {price.discounted_from && <Text
                            style={{textDecoration: 'line-through'}}
                        >
                            {price.discounted_from} лв
                        </Text>}
                        <Text
                            weight={price.discounted_from ? 'bold' : 'normal'}
                            color={price.discounted_from ? 'status-error' : 'text-strong'} >{price.value} лв</Text>
                    </Box>
                </Box>)}
            </Box>
        </Box>
    )
}

const Pricing = () => {
    return (
        <Box id='pricing' justify='center' pad={{horizontal: 'xlarge', vertical: 'small'}} align='center'>
            <Heading level={2} size='large'>Ценоразпис</Heading>
            <Box direction='row' gap='large' justify='center' wrap>
                <Panel
                    title='Аркадни игри'
                    description={<Paragraph style={{width: 300}}>Игри с <strong>продължителност от 3 до 15 минути</strong>. Най-често за двама играчи (Creed, Table tennis, Beat Saber). <strong>Резервация за тях се прави по телефона</strong>.</Paragraph>}
                    prices={[{name: 1, value: 3}, {name: 2, value: 6}]}
                    priceHeading='Цена за 1 игра'
                    image={<StaticImage
                        src='../images/image3s.webp'
                        alt='Аркадни игри'
                        height={300}
                        style={{borderRadius: 10}}
                    />}
                />
                <Panel
                    title='Steam игри'
                    description={<Paragraph style={{width: 300}}>Голям набор от VR игри в платформата Steam, които можете да разгледате по-подробно в секцията "<Anchor href='#pick-a-game'>Избери игра</Anchor>".</Paragraph>}
                    prices={[{name: 1, value: 25}, {name: 2, value: 45, discounted_from: 50}, {name: 3, value: 65, discounted_from: 70}, {name: 4, value: 80, discounted_from: 90}]}
                    priceHeading='Цена за 1 час'
                    image={<StaticImage
                        src='../images/image.webp'
                        alt='Steam игри'
                        height={300}
                        style={{borderRadius: 10}}
                    />}
                />
                <Panel
                    title={<Box align='center'>
                        <Heading level={3}>Ескейп стаи</Heading>
                        {/*<Text weight='bold' size='xlarge' color='status-error'>Очаквайте скоро</Text>*/}
                    </Box>}
                    description={<Paragraph style={{width: 300}}>VR Ексейп стаите са кооперативни игри за 2-ма до 4-ма играчи, които трябва да работят в екип, за да изпълнят загадките и пъзелите, и да излязат успешно в рамките на <strong>1 час</strong>.</Paragraph>}
                    prices={[{name: 2, value: 45, discounted_from: 50}, {name: 3, value: 65, discounted_from: 70}, {name: 4, value: 80, discounted_from: 90}]}
                    priceHeading='Цена за 1 игра'
                    image={<StaticImage
                        src='../images/image2.webp'
                        alt='Ескейп стаи'
                        height={300}
                        style={{borderRadius: 10}}
                    />}
                />
            </Box>
        </Box>
    );
};

export default Pricing;