import React from 'react';
import {Box, Heading, Paragraph, Text} from "grommet";
import { StaticImage } from "gatsby-plugin-image"

export const Feature = ({title, text}) => (
    <Box gap='small' width={{max: 'small'}}>
        <Heading level={5} margin={{vertical: 'small'}}>{title}</Heading>
        {typeof text === 'string' ? <Text>{text}</Text> : text}
    </Box>
)

const HowDoesItWork = ({...rest}) => {
    return (
        <Box {...rest}>
            <Heading level={2} size='xlarge' color='#f3e4d2' margin={{bottom: 'none', left: 'xlarge'}} style={{lineHeight: '40px'}}>Как работи?</Heading>
            <Box pad={{vertical: 'xlarge', horizontal: 'medium'}} background='#f3e4d2' justify='center' align='center' direction='row-responsive' gap='xlarge'>
                <Box>
                    <StaticImage
                        src='../images/vive-pro-2.webp'
                        alt='Vive Pro 2 Headset'
                        height={380}
                    />
                    {/*<Image*/}
                    {/*    // width='673px'*/}
                    {/*    height='361px'*/}
                    {/*    fit='contain'*/}
                    {/*    // src='//www.matts-digital.com/Files/132319/Img/15/HTC-VIVE-Pro-2-MD-big.png'*/}
                    {/*    src='//mrtv.co/wp-content/uploads/2021/05/VIVE-Pro-2-klein.png'*/}
                    {/*/>*/}
                </Box>
                <Box background='white' elevation='large' round='medium' pad='medium' alignSelf='center'>
                    <Paragraph>
                        Всеки играч разполага с VR очила, слушалки и джойстик, чрез които той вижда, чува и контролира средата около него, която е съгласувана в реално време със същата на неговите съотборници. Той разполага с клетка, с големина 3м х 3м, в която всяко негово движение бива засичано от сензори.
                    </Paragraph>
                </Box>
            </Box>
            <Box background='#f1dec9' pad='large' direction='row-responsive' justify='center' gap='large'>
                <Feature
                    title='Висока Резолюция 5K'
                    text='2448 x 2448px на око (4996 x 4996px заедно).'
                />
                <Feature
                    title='3D Съраунд Аудио'
                    text='Hi-Res слушалки и микрофон.'
                />
                <Feature
                    title='Екран'
                    text='Два IPS 120Hz дисплея. Полезрение от 120°.'
                />
                <Feature
                    title='Регулируемост'
                    text='Регулируеми слушалки и IPD.'
                />
            </Box>
        </Box>
    );
};

export default HowDoesItWork;